<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <!--begin::Form Wizard-->
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(_handleSubmit)">
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("currencies_management.code") }}</label>
                  <div class="col-lg-9 col-xl-9">
                    <input
                      class="form-control"
                      type="text"
                      :value="currency.code"
                      disabled
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("currencies_management.currency") }}</label>
                  <div class="col-lg-9 col-xl-9">
                    <input
                      class="form-control"
                      type="text"
                      :value="currency.name"
                      disabled
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("currencies_management.symbol") }}</label>
                  <div class="col-lg-9 col-xl-9">
                    <input
                      class="form-control"
                      type="text"
                      :value="currency.symbol"
                      disabled
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("currencies_management.digit") }}</label>
                  <div class="col-lg-9 col-xl-9">
                    <input
                      class="form-control"
                      type="text"
                      :value="currency.decimal"
                      disabled
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("currencies_management.min") }}</label>
                  <validation-provider
                    v-if="!isLoading"
                    rules="required"
                    :name="$t('currencies_management.min')"
                    v-slot="{ classes, errors }"
                    class="col-lg-9 col-xl-9"
                  >
                    <currency-input
                      class="form-control"
                      autocomplete="off"
                      :distractionFree="false"
                      :precision="decimal"
                      v-model="formData.min_pay"
                      placeholder="0.00"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("currencies_management.max") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('currencies_management.max')"
                    v-slot="{ classes, errors }"
                    class="col-lg-9 col-xl-9"
                    v-if="!isLoading"
                  >
                    <currency-input
                      class="form-control"
                      autocomplete="off"
                      :distractionFree="false"
                      :precision="decimal"
                      v-model="formData.max_pay"
                      placeholder="0.00"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("currencies_management.deposit") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('currencies_management.deposit')"
                    v-slot="{ classes, errors }"
                    class="col-lg-9 col-xl-9"
                    v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedDeposit"
                      :options="allStatus"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeDeposit"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="name"
                      label="name"
                      :placeholder="$t('commons.choosen_status')"
                      :class="classes"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="option" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="noOptions">{{
                        "List is empty"
                      }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("currencies_management.withdraw") }}</label>
                  <validation-provider
                    rules="required"
                    :name="$t('currencies_management.withdraw')"
                    v-slot="{ classes, errors }"
                    class="col-lg-9 col-xl-9"
                    v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedWithdraw"
                      :options="allStatus"
                      :searchable="false"
                      :allow-empty="false"
                      open-direction="bottom"
                      @input="onChangeWithdraw"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="name"
                      label="name"
                      :placeholder="$t('commons.choosen_status')"
                      :class="classes"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="option" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="noOptions">{{
                        "List is empty"
                      }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-content-end border-top mt-5 pt-10">
            <button
              type="submit"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
              :disabled="isSubmitting"
            >
              {{ $t("commons.submit") }}
            </button>
          </div>
          <!--end::Actions-->
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import i18n from '@/config/_i18n'
import { Skeleton } from 'vue-loading-skeleton';
import { mapActions, mapState } from 'vuex';

const all_status = [
  { value: 1, name: i18n.t("institutions.active_status") },
  { value: 0, name: i18n.t("institutions.passive_status") }
]

export default {
  name: "UpdateCurrency",
  components: {
      Multiselect,
      Skeleton
  },
  data() {
    return {
      formData: {
        min_pay: null,
        max_pay: null,
        deposit: 0,
        withdraw: 0,
      },
      allStatus: all_status,
      selectedDeposit: "",
      selectedWithdraw: ""
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.currenciesManagement.isLoading,
      isSubmitting: (state) => state.currenciesManagement.isSubmitting,
      currency: (state) => state.currenciesManagement.currencyEdit,
    }),
    decimal() {
      return this.currency?.decimal || 8;
    }
  },
  methods: {
    ...mapActions("currenciesManagement", ["GET_EDIT", "UPDATE"]),
    _handleSubmit() {
        this.$refs.form.reset();
        this.UPDATE({
          id: this.$route.params.id,
          formData: this.formData
        })
    },
    onChangeDeposit() {
      this.formData.deposit = this.selectedDeposit.value;
    },
    onChangeWithdraw() {
      this.formData.withdraw = this.selectedWithdraw.value
    }
  },
  created() {
    this.GET_EDIT(this.$route.params.id).then(() => {
      const {min_pay, max_pay, deposit, withdraw} = this.currency;
      this.formData.min_pay = Number(min_pay) || 0;
      this.formData.max_pay = Number(max_pay) || 0;
      this.formData.deposit = deposit ? 1 : 0;
      this.formData.withdraw = withdraw ? 1 : 0;
      this.selectedDeposit = all_status.find(item => Boolean(Number(item.value)) === deposit)
      this.selectedWithdraw = all_status.find(item => Boolean(Number(item.value)) === withdraw)

    })
  },
};
</script>
